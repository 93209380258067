// import { useEffect } from "react";

// const LandbotChat = () => {
//   useEffect(() => {
//     const initLandbot = () => {
//       if (!window.myLandbot) {
//         const script = document.createElement("script");
//         script.type = "module";
//         script.async = true;
//         script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.mjs";
        
//         script.onload = () => {
//           window.myLandbot = new window.Landbot.Livechat({
//             configUrl: "https://storage.googleapis.com/landbot.online/v3/H-2783542-Q81YTOEJPOLBQDPU/index.json",
//           });
//         };

//         document.body.appendChild(script);
//       }
//     };

//     window.addEventListener("mouseover", initLandbot, { once: true });
//     window.addEventListener("touchstart", initLandbot, { once: true });

//     return () => {
//       window.removeEventListener("mouseover", initLandbot);
//       window.removeEventListener("touchstart", initLandbot);
//     };
//   }, []);

//   return null; // No UI element needed, as the chatbot loads separately
// };

// export default LandbotChat;


import { useEffect } from "react";

const LandbotChat = () => {
  useEffect(() => {
    const initLandbot = () => {
      if (!window.myLandbot) {
        const script = document.createElement("script");
        script.type = "module";
        script.async = true;
        script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.mjs";

        script.onload = () => {
          window.myLandbot = new window.Landbot.Livechat({
            configUrl: "https://storage.googleapis.com/landbot.online/v3/H-2783542-Q81YTOEJPOLBQDPU/index.json",
          });
        };

        document.body.appendChild(script);
      }
    };

    window.addEventListener("mouseover", initLandbot, { once: true });
    window.addEventListener("touchstart", initLandbot, { once: true });

    return () => {
      window.removeEventListener("mouseover", initLandbot);
      window.removeEventListener("touchstart", initLandbot);
    };
  }, []);

  return null; // The chatbot appears separately, no UI elements are needed here
};

export default LandbotChat;
