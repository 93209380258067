import React from "react";
import styled from "styled-components";

const socialLinks = [
  {
    platform: "facebook",
    url: "https://www.facebook.com/FearlessAgainstRabies",
    iconClass: "fa-brands fa-facebook-f",
    bgColor: "#3b5998",
  },
  {
    platform: "instagram",
    url: "https://www.instagram.com/fearless_against_rabies",
    iconClass: "fa-brands fa-instagram",
    bgColor: "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
  },
  {
    platform: "linkedin",
    url: "https://www.linkedin.com/company/fearless-against-rabies-hbi",
    iconClass: "fa-brands fa-linkedin-in",
    bgColor: "#0077b5",
  },
  {
    platform: "twitter",
    url: "https://twitter.com/F_AgainstRabies",
    iconClass: "fa-brands fa-x-twitter",
    bgColor: "#000000",
  },
  {
    platform: "youtube",
    url: "https://www.youtube.com/channel/UCxx7DEVTtMbxy4yA-q4Ovvw",
    iconClass: "fa-brands fa-youtube",
    bgColor: "#FF0000",
  },
];

const SideSocialIcons = () => {
  return (
    <SideSocialIconsWrapper>
      <ul>
        {socialLinks.map(({ platform, url, iconClass, bgColor }) => (
          <li key={platform} style={{ background: bgColor }} className={platform}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <i className={iconClass}></i>
            </a>
          </li>
        ))}
      </ul>
    </SideSocialIconsWrapper>
  );
};

export default SideSocialIcons;

const SideSocialIconsWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  background-color: white;
  border-radius: 0 8px 8px 0;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  z-index: 20;

  ul {
    list-style: none;
    margin: 0;
    padding: 10px;
  }

  li {
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-decoration: none;

      i {
        font-size: 22px;
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    transform: scale(0.8);
    left: calc(100% - 53px);
    top: 40%;
    border-radius: 8px 0 0 8px;
  }
`;
