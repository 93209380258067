import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import BASE_API_URL from "../apis";
import { NavLink } from "react-router-dom";

export const OathContent = () => {
  const [name, setName] = useState("Your Name");
  const [totalOaths, setTotalOaths] = useState(0);
  const [oathSubmitedMsg, setOathSubmitedMsg] = useState(null);

  const getOathNum = async () => {
    try{
      const response = await axios.get(`${BASE_API_URL}/user/oath-numbers`);
      if(response.data.oathnum){
        setTotalOaths(response.data.oathnum);
      } else{
        console.log("something went wrong at getting oath numbers")
      }
    }catch(err){ console.log("something went wrong at getting oath numbers"); }
  }
  useEffect(() => {
    getOathNum();
  }, [])

  const handleBlur = (e) => {
    setName(e.target.innerText.trim());
  };

  const handleSubmit = async () => {
    if (!name || name === "Your Name") {
      setOathSubmitedMsg("Please enter a valid name.");
      return;
    }

    try {
      const response = await axios.post(`${BASE_API_URL}/user/oath`, { name });
      if (response.data.Status === "OK") {
        setOathSubmitedMsg("Oath submitted successfully!");
        setName("Your Name");
        getOathNum();
      } else {
        setOathSubmitedMsg("Something went wrong!");
      }
    } catch (err) {
      setOathSubmitedMsg("Something went wrong!");
    }
  };

  return (
    <Wrapper>
      <div id="oath">
        <h2 className="heading-2">
          <span className="primaryColor">{4046 + totalOaths} </span>people have taken the
          <span className="secondaryColor"> Oath </span>to fight against rabies
        </h2>
        <div className="oath">
          <p className="oath-text">
            I&nbsp;
            <span
              contentEditable
              suppressContentEditableWarning
              className="editable-name px-5"
              onBlur={handleBlur}
            >
              {name}
            </span>
            &nbsp;understand that rabies is a fatal disease that is majorly
            transmitted through rabid animals. I am aware that rabies can be
            100% prevented by vaccinating pets and humans. I take the oath to
            spread awareness among my family and friends regarding rabies
            pre-exposure and post-exposure vaccination and be a part of making
            the world free from rabies.
          </p>
          <button onClick={handleSubmit} className="py-1 px-4 border-0 rounded-2 bgPrimary bgHoverSecondary text-white"> Oath </button>
          {oathSubmitedMsg && (
            <p className={`small mt-2 ${oathSubmitedMsg.includes("success") ? "text-success" : "text-danger"}`}>
              {oathSubmitedMsg}
            </p>
          )}

          <div className="share-icons mt-5">
            <NavLink to={"https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer.php%3Ft%3DLet%2Byour%2Bchild%2Bbe%2Bfearless%2BProtect%2Bfrom%2Brabies%26u%3Dhttps%253A%252F%252Ffearlessagainstrabies.com%252Foath&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB"} >
              <div className="icons fb">
                <img src="./images/svg/facebook.svg" alt="" /> share
              </div>
            </NavLink>
            <NavLink to={"https://twitter.com/intent/post?text=Let%20your%20child%20be%20fearless%20Protect%20from%20rabies&url=https%3A%2F%2Ffearlessagainstrabies.com%2Foath"} >
              <div className="icons twitter">
                <img src="./images/svg/twitter.svg" alt="" /> tweet
              </div>
            </NavLink>
            <NavLink to={"#"}>
              <div className="icons wp">
                <img src="./images/svg/whatsapp.svg" alt="" /> tweet
              </div>
            </NavLink>
            <NavLink to={"#"}>
              <div className="icons linkedin">
                <img src="./images/svg/linkedin.svg" alt="" /> share
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .oath {
    border: 3px dashed var(--primaryColor);
    padding: 35px;
    margin: 35px auto;
    text-align: center;
  }

  .oath-text {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }

  .editable-name {
    display: inline-block;
    font-weight: bold;
    border-bottom: 1px dashed #000;
    padding: 2px 5px;
    min-width: 80px;
    cursor: text;
    outline: none;
  }

  .share-icons {
      display: flex;
      gap: 10px;
      justify-content: center;
      margin-top: 20px;
    }
  .icons {
      display: flex;
      color: #fff;
      font-size: 12px;
      background-color: green;
      justify-content: center;
      align-items: center;
      padding: 0 15px 0 0;

      img {
        width: 35px;
        height: 35px;
        padding: 10px;
      }
    }
    .fb {
      background-color: #4267b2;
    }
    .twitter {
      background-color: #000000;
    }
    .wp {
      background-color: #25d366;
    }
    .linkedin {
      background-color: #0077b5;
    }

`;
