import axios from 'axios';
import React, { useState } from 'react';
import BASE_API_URL from "../apis/index";
import TextField from "@mui/material/TextField";

function FormRegisterRabiesEducation({ isMobile }) {
    const [sclName, setSclName] = useState("");
    const [sclAddr, setSclAddr] = useState("");
    const [sclEml, setSclEml] = useState("");
    const [sclNmbr, setSclNmbr] = useState("");
    const [formSbtMsg, setFormSbtMsg] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BASE_API_URL}/user/school-register`, { sclName, sclAddr, sclEml, sclNmbr });

            if (response.data.Status === "OK") {
                setSuccess(true);
                setFormSbtMsg("School registered successfully!");
                // Clear the form fields
                setSclName("");
                setSclAddr("");
                setSclEml("");
                setSclNmbr("");
            } else {
                setSuccess(false);
                setFormSbtMsg("Something went wrong. Try again!");
            }
        } catch (err) {
            setSuccess(false);
            setFormSbtMsg("Something went wrong. Try again!");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="emailForm">
            <div className="form-fields">
                <TextField
                    required
                    type="text"
                    label="Your School Name"
                    value={sclName}
                    onChange={(e) => setSclName(e.target.value)}
                    size="small"
                    className={isMobile ? 'w-100' : "w-33"}
                />
                <TextField
                    required
                    label="Your School Address"
                    type='text'
                    value={sclAddr}
                    onChange={(e) => setSclAddr(e.target.value)}
                    size="small"
                    className={isMobile ? 'w-100' : "w-33"}
                />
                <TextField
                    required
                    label="Email"
                    type='email'
                    value={sclEml}
                    onChange={(e) => setSclEml(e.target.value)}
                    size="small"
                    className={isMobile ? 'w-100' : "w-33"}
                />
                <TextField
                    required
                    label="Number"
                    type="tel"
                    value={sclNmbr}
                    onChange={(e) => setSclNmbr(e.target.value)}
                    size="small"
                    className={isMobile ? 'w-100' : "w-33"}
                />
            </div>
            {formSbtMsg && <p className={success ? "text-success" : "text-danger"}>{formSbtMsg}</p>}
            <button type="submit" className="px-4 py-2 text-white rounded border-0 bgSecondary bgHoverPrimary">
                Submit
            </button>
        </form>
    );
}

export default FormRegisterRabiesEducation;
