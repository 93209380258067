import React from "react";
import styled from "styled-components";
import ContactUsForm from "./ContactUsForm ";

function ContactUsFormWrpr({ doctorDetails }) {
  return (
    <Wrapper className="row">
      <div className="col-md-5 py-md-0 py-5">
        <img src="/images/mask/doctor_dog.jpg" alt="docdog" className="img-fluid rounded-4" />
      </div>
      <div className="col-md-7 ps-md-5">
        <div className="row rounded-4 bg-white w-100">
          <h2 className="mb-3">Contact Us</h2>
          <ContactUsForm doctorDetails={doctorDetails} />
        </div>
      </div>
    </Wrapper>
  );
}

export default ContactUsFormWrpr;

const Wrapper = styled.section`
img{
  ${'' /* mask-image: src("/images/mask/pawprint.svg"); */}
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;
