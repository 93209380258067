import styled from "styled-components";
import VaccineSchedulerSection from "./HomeComponents/VaccineSchedulerSection";

export default function RabiesVaccineSchedule() {
  return (
    <Wrapper className="container py-md-5 py-4 px-md-0 px-4">
      <VaccineSchedulerSection />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
