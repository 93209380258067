import styled from "styled-components";
import DoctorsPage from "./DoctorsPage";

export default function RabiesAndDoctor({ auth, doctorId, setAuth, setDoctorId }) {
  return (
    <Wrapper>
      <div className="pageBanner"></div>
      <DoctorsPage doctorId={doctorId} setDoctorId={setAuth} auth={auth} setAuth={setAuth} />
    </Wrapper>
  );
}
const Wrapper = styled.section`
  .pageBanner {
    height: 35vh;
    background: #f1f1f1;
  }
  ${'' /* .RaD {
    text-align: center;
    .form {
      margin: 35px auto;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
    .frmBtn {
      background-color: var(--secondaryColor);
      &:hover {
        background-color: var(--primaryColor);
      }
    }
  } */}

  @media (max-width: 768px) {
    .pageBanner {
      height:0;
      background-image: url("./images/banner/login-2.png");
    }
  }
`;
