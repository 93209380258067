import axios from 'axios';
import React, { useState } from 'react';
import BASE_API_URL from "../apis/index";
import TextField from '@mui/material/TextField';

function FormNwsLtrScrb() {
  const [email, setEmail] = useState("");
  const [emlSbtMsg, setEmlSbtMsg] = useState({ type: "", msg: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_API_URL}/user/newsletter-subscrb`, { email });
      if (response.data.Status === "OK") {
        setEmlSbtMsg({ type: "success", msg: "Subscription successful!" });
        setEmail("");
      } else if (response.data.Status === "emailExist") {
        setEmlSbtMsg({ type: "warning", msg: "Email already subscribed!" });
        setEmail("");
      } else {
        setEmlSbtMsg({ type: "error", msg: "Something went wrong. Try again!" });
      }
    } catch (err) {
      setEmlSbtMsg({ type: "err", msg: "Something went wrong, Please try after sometime." })
    }
  }

  return (
    <div>
      <h2 className="heading-3 secondaryColor"> Subscribe to the newsletter </h2>
      <form onSubmit={handleSubmit} className="emailForm">
        <TextField
          required
          fullWidth
          margin="dense"
          type="email"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          label="Email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          helperText={emlSbtMsg.msg} // Only display the message
          error={emlSbtMsg.type === "error"} // Show error style if applicable
        />
        <button type="submit" variant="contained" className="btn"> Submit </button>
      </form>
    </div>
  );
}

export default FormNwsLtrScrb;