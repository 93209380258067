import React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export default function SideMenu({ menuItems }) {
    return (
        <Wrapper>
            <Navbar className='siedeNavMenu'>
                <Nav className='navItems flex-column w-100 py-0'>
                    {
                        menuItems.map((item, idx) => (
                            <NavLink
                                key={idx}
                                to={`/${item.link}`}
                                className='nav-link w-100'
                                activeclassname="active"
                                >
                                {item.title}
                            </NavLink>
                        ))
                    }
                </Nav>
            </Navbar>
        </Wrapper>
    );
}

const Wrapper = styled.div`
.siedeNavMenu {
  ${'' /* padding: 3px 1px; */}
  overflow: hidden;
  box-shadow: none;
  .navItems {
    text-align: start;
    text-transform: uppercase !important;
    .nav-link {
      background-color: #f8f8f8;
      padding: 12px 16px !important;
      margin-bottom: 5px;
      transition: 0.2s;
      &:hover {
        background-color: #f1f1f1;
        color: var(--secondaryColor);
      }
      &.active {
        color: white;
        background-color: var(--secondaryColor);
      }
    }
  }
}
`
