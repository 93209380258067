import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";

export default function HomeBannerMobile() {
  return (
    <Wrapper>
      <div className="heroSec  m-2 bg-light rounded-5 overflow-hidden">
        <Carousel slide>
          {[
            { imageSrc: "homeBnr1", caption: <Caption1 /> },
            { imageSrc: "homeBnr2", caption: <Caption2 /> },
            { imageSrc: "homeBnr3", caption: <Caption3 /> },
            { imageSrc: "homeBnr4", caption: <Caption4 /> }
          ].map((item, index) => (
            <Carousel.Item key={index}>
              <img src={`/images/homeBanners/${item.imageSrc}.webp`} alt={item.imageSrc} className="bannerImg" />
              <div className="text-dark container py-4 px-3">
                {item.caption}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Wrapper>
  );
}

const Caption1 = () => {
  return (
    <div className="slide-info">
      <h2 className="heading-1 primaryColor"> WE BOTH ARE PROTECTED AGAINST RABIES </h2>
      <div className="">
        <h2 className="heading-2"> ARE YOU TOO? </h2>
        <button className="bgSecondary text-white border-danger px-4 py-2 rounded"> Learn More </button>
      </div>
    </div>
  );
};

const Caption2 = () => {
  return (
    <div className="slide-info">
      <h2 className="heading-2"> LET YOUR PET LOVE BE FEARLESS </h2>
      <h2 className="heading-1 primaryColor">PROTECT YOURSELF</h2>
    </div>
  );
};

const Caption3 = () => {
  return (
    <div className="slide-info">
      <h1 className="heading-2"> LET YOUR CHILD BE FEARLESS </h1>
      <h2 className="heading-1 primaryColor"> PROTECT FROM RABIES </h2>
    </div>
  );
};

const Caption4 = () => {
  return (
    <div className="slide-info">
      <h2 className="heading-2"> LET YOUR MORNING RUN BE FEARLESS </h2>
      <h2 className="heading-1 primaryColor"> PROTECT YOURSELF FROM RABIES </h2>
    </div>
  );
};

const Wrapper = styled.div`
  .carousel-inner{ height: 65vh; }
  .heroSec {
    .bannerImg{
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
  }
  .carousel-indicators {
    gap: 5px;
    button {
      background-color: #ffc7cd;
      border: #1d1d1d;
      gap: 10px;
      padding: 2px;
      width: 20px;
      border-radius: 5px;
    }
    .active {
      background-color: var(--secondaryColor);
    }
  }
  span.carousel-control-next-icon, span.carousel-control-prev-icon {
    display: none;
  }

  @media (max-width: 768px) {}
`;
