import React from 'react';
import styled from "styled-components";
import SideMenu from '../components/SideMenu';
import PageBanner from '../components/PageBanner';
import { SotV } from '../components/SotV';

function SORVPage() {
  return (
    <Wrapper>
      <PageBanner bannerImg={"rabies-and-you"} h1={"Get Vaccinated"} h3={"LET YOUR PET LOVE BE FEARLESS"} />
      <div className="container mx-auto py-5 px-md-0 px-4">
            <div className='row gx-md-5 gx-0'>
          <div className="col-md-3">
            <SideMenu
              menuItems={[
                { title: "About Rabies", link: "rabies-and-you" },
                { title: "How will I know if I have rabies?", link: "how-will-i-know-if-i-have-rabies" },
                { title: "Diagnosing Rabies", link: "diagnosing-rabies" },
                { title: "How is rabies treated?", link: "how-is-rabies-treated" },
                { title: "Can rabies be prevented?", link: "can-rabies-be-prevented" },
                { title: "Vaccination", link: "vaccination" },
                { title: "Safety of the rabies vaccine", link: "safety-of-the-rabies-vaccine" },
              ]}
            />
          </div>
          <div className="col-md-9 py-md-0 py-4">
            <SotV />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default SORVPage;

const Wrapper = styled.section``;